import axios from '@/axios.js'

export default {
  //NO FILTER SEMPRE DEVO FAZER A CONSULTA A API PARA RENOVAR O ESTADO POR COMPLETO no GRID
  fetchFilter ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/generalCash/get`, payload)
        .then((response) => {
          commit('REFRESH', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  //NO FILTER SEMPRE DEVO FAZER A CONSULTA A API PARA RENOVAR O ESTADO POR COMPLETO no GRID
  fetchBatchPayment ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/transactions/getBatchPayment`, payload)
        .then((response) => {
          commit('REFRESH', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  batchPayment (context, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/transaction/batchPayments`, payload)
        .then((response) => {
          // commit('REFRESH', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  store (context, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/transaction`, payload)
        .then((response) => {
          // commit('REFRESH', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  chargeback ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${process.env.VUE_APP_API_URL}/transaction/chargeback/${payload.id}`, payload)
        .then((response) => {
          commit('UPDATE', response.data)
          commit('SET', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  destroy ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.delete(`${process.env.VUE_APP_API_URL}/financial/cashFlow/${payload.id}`)
        .then((response) => {
          commit('DELETE', payload.id)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  update ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/financial/cashFlow`, payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
