import Vue from 'vue'

export default {

  //MANIPULA O OBJETO DO ESTADO
  SET (state, payload) {
    state.generalTransaction = Object.assign({}, state.generalTransaction, payload) //precisa ser assim para manter a reatividade
  },

  //ADICIONA UM NOVO ÍTEM NO ARRAY
  ADD (state, payload) {
    state.generalTransactions.push(payload) //precisa ser assim para manter a reatividade
  },

  UPDATE (state, payload) {
    const index = state.generalTransactions.data.findIndex((o) => o.id === payload.id)
    Vue.set(state.generalTransactions.data, index, payload)
  },

  //SUBSTITUI O ARRAY POR UM NOVO
  REFRESH (state, payload) {
    state.generalTransactions = payload
  },

  //EXCLUI DO ARRAY
  DELETE (state, payload) {
    const index = state.generalTransactions.data.findIndex(o => o.id === payload)
    state.generalTransactions.data.splice(index, 1) //precisa ser assim para manter a reatividade
    state.generalTransactions.total = state.generalTransactions.total - 1 //precisa ser assim para manter a reatividade
    if (state.generalTransaction && state.generalTransaction.id === payload) {
      state.generalTransaction = Object.assign({}, {}) //precisa ser assim para manter a reatividade
    }
  }
}
