import state from './moduleGeneralCashState.js'
import mutations from './moduleGeneralCashMutations.js'
import actions from './moduleGeneralCashActions.js'
import getters from './moduleGeneralCashGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

